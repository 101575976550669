/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { Layout } from '@components/Layout';
import { observer } from 'mobx-react';
import { Spinner, Text, Heading, Box } from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Form } from '@components/Form';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { REGISTER } from '@constants';
import { useStores } from '@stores';


interface IRegisterValidatedUserPage {
  location: any;
  data: IMarkdownData;
}

const ValidatedUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HtmlContent = styled.div`
  text-align: center;
`;

const RegisterValidatedUserPage: FC<IRegisterValidatedUserPage> = observer(({
  data: {
    markdownRemark: { html },
  },
  location,
}) => {
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const {
    authStore: {
      getValidatedUserInfo, validatedUser, createUser, state,
    },
  }: {
    authStore: IAuthStore,
  } = useStores();

  useEffect(() => {
    const { search } = location;
    if (!validatedUser) {
      try {
        getValidatedUserInfo(search);
      } catch {
      }
    }
  }, [validatedUser, getValidatedUserInfo]);

  const onSubmit = async (data: {}, callback: Function) => {
    const formData = { ...data, ...{ state: location.search.split('&state=')[1] } };
    const res = await createUser(formData, callback);
    setRegistrationCompleted(true);
    return res;
  };

  const validateForm = ({
    password, passwordAgain,
  }: { password?: string; passwordAgain?: string }) => {
    const errors = {} as { [key: string]: string };
    if (password && password !== passwordAgain) {
      errors.passwordAgain = 'fields.validation.passwordMatch';
    }
    return errors;
  };

  return (
    <Layout twoColumnLayout>
      {!validatedUser && state === 'Loading' && <Spinner color="mainBlue" />}
      <Box pl="2" pr="2">
        {validatedUser && !registrationCompleted && (
          <ValidatedUserContainer>
            <Heading level="3">
              <M id="common.greeting" values={{ name: validatedUser.name }} />
            </Heading>
            <Text mt="2" mb="2" fontWeight="400">
              <M id="pages.register.createCredentials" />
            </Text>
            <Form
              id="register"
              fields={REGISTER}
              values={{}}
              onSubmit={onSubmit}
              submitText="pages.register.createCredentials"
              validate={validateForm}
              styles={['centerLabels', 'singleColumn']}
            />
          </ValidatedUserContainer>
        )}
        {validatedUser && registrationCompleted && html && (
          <HtmlContent dangerouslySetInnerHTML={{ __html: html }} />
        )}
      </Box>
    </Layout>
  );
});

export const pageQuery = graphql`
query RegistrationQuery {
  markdownRemark(frontmatter: {path: {eq: "/register/validated-user/"}}) {
    frontmatter {
      title
      path
    }
    html
  }
}
`;

export default RegisterValidatedUserPage;